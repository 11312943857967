import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue";
const _hoisted_1 = {
  class: "UserDownLoadLogView"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
import { onMounted, ref } from "vue";
import Apis_User from "@/apis/Apis_User";
import TableNetWork from "@/components/Table_NetWork/TableNetWork";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import HeaderNavBar from "@/components/Header/HeaderNavBar";
import LangBase from "@/utils/Lang/common/LangBase";
export default {
  __name: 'UserDownLoadLog',
  props: ['UserName'],
  setup(__props) {
    const props = __props;
    const pages = ref({
      count: 0,
      page: 1,
      size: 20,
      talbeData: [],
      //{title,label,data}
      headerTitle: '下载记录',
      checkbox: false //表格是否启用多选框
    });

    const GetCount = () => {
      Apis_User.getUserCount(props.UserName).then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        pages.value.count = Number(res.count);
        if (DataIsNull()) {
          VantMsgLib.alertError('该用户无下载记录');
        } else {
          GetTableData();
        }
      });
    };
    const DataIsNull = () => {
      return pages.value.count <= 0;
    };
    const langType = new LangBase().getLanguage();
    const GetTableData = () => {
      console.log('langType', langType);
      Apis_User.getUserDownLoad(props.UserName, pages.value.page, pages.value.size).then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        let arr = res.data || [];
        pages.value.talbeData = [];
        for (let arrKey in arr) {
          let {
            time,
            model
          } = arr[arrKey];
          if (langType !== 'zh') {
            model = arr[arrKey]['model_en'];
          }
          let obj = {
            title: model,
            label: time,
            data: arr[arrKey]
          };
          pages.value.talbeData.push(obj);
        }
        // console.log('log',pages.value)
      });
    };

    onMounted(() => {
      GetCount();
    });
    const TableNetWorkEvent = options => {
      // [事件类型]1.工具栏 2.选中用户变动 3.点击某个表项 4.页数变动
      console.log('TableNetWorkEvent', options);
      if (options.type === 1) {
        VantMsgLib.notify(4, '暂无更多');
      }
      switch (options.type) {
        case 1:
          VantMsgLib.notify(4, '暂无更多');
          break;
        case 4:
          pages.value.page = Number(options.data);
          GetTableData();
          break;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_empty = _resolveComponent("van-empty");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!DataIsNull() ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_unref(TableNetWork), {
        Options: pages.value,
        onTableEvent: TableNetWorkEvent
      }, null, 8, ["Options"])])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_unref(HeaderNavBar), {
        title: "下载记录"
      }), _createVNode(_component_van_empty, {
        image: "error",
        description: "No Data"
      })]))]);
    };
  }
};